<template lang="pug">
Navbar
.bg-image
  .padding-top
    .container.d-none.d-md-block
      nav(aria-label='breadcrumb')
      ol.breadcrumb
        li.breadcrumb-item
          router-link.text-black.text-decoration-none(to='home') Home
        li.breadcrumb-item.active(aria-current='page') Website builder
    HeadingLayout(:title="title", :desc="desc")
.mb-5
  template(v-for="message in messages")
    FeatureList(:title="message.title", :desc="message.desc", :image="message.image")
Footer
</template>

<script>
import Navbar from "../../components/TheNavbar";
import Footer from "../../components/TheFooter";
import HeadingLayout from "../../layout/HeadingLayout";
import FeatureList from "../../components/FeatureList";
export default {
  name: "WebsiteBuilder",
  components: { FeatureList, HeadingLayout, Footer, Navbar },
  data() {
    return {
      title: "Build your unique online presence",
      desc: "Kloser gives you the easier way to create, design, manage and develop your web presence. You will get a FREE domain name which starts with your business name. ",
      messages: [
        {
          image: "/images/features/web/Web_USP_Image_1.png",
          title: "Generate a website that represents you",
          desc: "Nothing is more important than developing your business online presence. Unlike other e-commerce platforms, Kloser matters about your business profile as you are. You are one of many other agents to them but Kloser values every agent profile. You could name your own website freely and end it with Kloser domain name.",
        },
        {
          image: "/images/features/web/Web_USP_Image_2.png",
          title:
            "Create the listing the same way you create a social media stories ",
          desc: "Most of us are familiar with Story. We make the listing/ads creation the same way you create a story on Facebook, Instagram, TikTok. Upload or capture a picture and unleash your creativity with the editor tool. Image cropping, text editor, emoji, sticker, labelling- we have all you need.",
        },
        {
          image: "/images/features/web/Web_USP_Image_3.png",
          title: "Make Data Informed Decisions with Kloser dashboard",
          desc: "Get insightful info about your website. Learn how your leads interact with your website and which listing is performing. Use this data to improve your next business strategy. Get insights on your top leads, see how your lead is growing and monitor your lead generation over time.",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
